
import { Modal, Select, SelectOption, AlphanumericInput, Button } from "@lmig/lmds-react";
import { useState } from "react";
import "./QuoteModal.css"
function QuoteModal(props: {agencyID : string , zipCode : any, hideQuoteOption:boolean, agencyWebsite: any}) {

    const [quoteModalOpen, setQuoteModalOpen] = useState(false);
    const [quoteModalLOB, setQuoteModalLOB] = useState("");
    const [quoteModalZip, setQuoteModalZip] = useState("");

    const handleKeyDown = (event: any) => {
        if (event.key === "Enter") {
          window.open(getQuoteLink());
        }
      };

    const getQuoteLink = () => {
        if (quoteModalLOB === "auto")
            return `https://quote.safeco.com/consumer/${quoteModalLOB}/VPLaunch.aspx?id=${props.agencyID.slice(-8)}&zip=${quoteModalZip}&dc=FAADC`;
        else if (quoteModalLOB === "home")
            return `https://quote.safeco.com/consumer/home/safeco/contactinfoco.aspx?id=${props.agencyID.slice(-8)}&zip=${quoteModalZip}&dc=FAADC`;
        else if (quoteModalLOB === "rent" || quoteModalLOB === "condo")
            return `https://quote.safeco.com/consumer/home/safeco/contactinfoco.aspx?id=${props.agencyID.slice(-8)}&zip=${quoteModalZip}&dc=FAADC&Product=${quoteModalLOB}`;
        else
            return `https://tools.safeco.com/BPS/bps.aspx/${quoteModalLOB}?agentnum=${props.agencyID.slice(-8)}&zip=${quoteModalZip}&dc=FAADC`;
    }


    const isDisabled = () => {
        //*** disable quote option for agencies from California state ***//
        const myArray = props.zipCode.slice(-13);
        const state = myArray.split(" ")[0];
        //*** disable quote option for agencies not having website info ***//
        let websiteUrl: string = String(props.agencyWebsite).trim();
        if (websiteUrl) {
            return state === 'CA' ?  true : props.hideQuoteOption;
        }
        else {
            return true;   
        }        
    }

    const navigateToAgencyWebsite = () => {
        let url = props.agencyWebsite?.trim() ? (props.agencyWebsite.indexOf("http") !== -1 ? props.agencyWebsite : `http://${props.agencyWebsite}`) : undefined
        window.open(url);
    }

    return (
        <>
            <Button
                className="agency-banner-button"
                variant="primary"
                size="large"
                disabled={isDisabled()}
                style = {{display: isDisabled() ? "none" : ""}}
                onClick={() => navigateToAgencyWebsite()}>Get a quote</Button>           
            {/* get a quote modal */}
            <Modal
                isOpen={quoteModalOpen}
                size="medium"
                modalStyle="standard"
                mobileTakeover
                title="Get a Quote"
                closeFunc={() => setQuoteModalOpen(false)}
                className="quote-modal">
                <Select
                    id="select--default"
                    isRequired
                    labelVisual="Get a quote for"
                    onChange={e => setQuoteModalLOB(e.currentTarget.value)}>
                    <SelectOption value="auto">Auto</SelectOption>
                    <SelectOption value="home">Home</SelectOption>
                    <SelectOption value="rent">Renters</SelectOption>
                    <SelectOption value="condo">Condo</SelectOption>
                    <SelectOption value="boatquoterq">Boat or Watercraft</SelectOption>
                    <SelectOption value="mcquoterq">Motorcycle</SelectOption>
                    <SelectOption value="rvquoterq">RV</SelectOption>
                    <SelectOption value="umbquoterq">Umbrella</SelectOption>
                    <SelectOption value="firequoterq">Landlord Protection</SelectOption>
                </Select>
                <AlphanumericInput
                    id="Alphanumeric--default"
                    labelVisual="Zip code"
                    value=""
                    mask="zipCode"
                    onKeyDown={(e) => handleKeyDown(e)}
                    onChange={e => setQuoteModalZip(e.currentTarget.value)}
                />
                <Button size="medium" href={getQuoteLink()} target="_blank" dynamicWidth variant="primary">Go</Button>
            </Modal></>);

}

export default QuoteModal;
